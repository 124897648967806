<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      Regions
      <router-link slot="right" :to="{ name: 'region.new' }" class="button">
        New Region
      </router-link>
    </hero-bar>
    <section class="section is-main-section">
      <region-table :checkable="true"/>
    </section>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import RegionTable from '@/components/SSIPLENGG/RegionTable.vue'
import HeroBar from '@/components/HeroBar'
export default {
  name: 'Regions',
  components: {
    HeroBar,
    TitleBar,
    RegionTable
  },
  computed: {
    titleStack () {
      return ['Admin', 'Regions']
    }
  }
}
</script>
